export enum SkillType {
    LANG = 'programming_language',
    FMW = 'frameworks',
    ENGINEER = 'engineering',
    TOOL = 'tooling',
    INFRA = 'infrastructure',
    PEOPLE = 'people',
    MANAGE = 'management'
}

export interface ISkill {
    name: string;
    skill: number;
    logo: string;
    type: SkillType
}

const skills: ISkill[] = [
    { name: 'TypeScript/Javascript', skill: 10, logo: '', type: SkillType.LANG },
    { name: 'Python', skill: 8, logo: '', type: SkillType.LANG },
    { name: 'Solidity', skill: 8, logo: '', type: SkillType.LANG },
    { name: 'Java', skill: 7, logo: '', type: SkillType.LANG },
    { name: 'C#', skill: 8, logo: '', type: SkillType.LANG },
    { name: 'Groovy', skill: 6, logo: '', type: SkillType.LANG },
    { name: 'C++', skill: 6, logo: '', type: SkillType.LANG },

    { name: 'GraphQL', skill: 8, logo: '', type: SkillType.FMW },
    { name: 'React', skill: 10, logo: '', type: SkillType.FMW },
    { name: 'Express', skill: 10, logo: '', type: SkillType.FMW },
    { name: 'Django', skill: 8, logo: '', type: SkillType.FMW },
    { name: 'Flask', skill: 8, logo: '', type: SkillType.FMW },
    { name: 'Spring', skill: 8, logo: '', type: SkillType.FMW },

    { name: 'User First Design', skill: 10, logo: '', type: SkillType.ENGINEER },
    { name: 'Software Architecture', skill: 10, logo: '', type: SkillType.ENGINEER },
    { name: 'System design', skill: 10, logo: '', type: SkillType.ENGINEER },
    { name: 'Testing', skill: 10, logo: '', type: SkillType.ENGINEER },
    { name: 'Agile', skill: 10, logo: '', type: SkillType.ENGINEER },
    { name: 'TDD', skill: 10, logo: '', type: SkillType.ENGINEER },
    { name: 'Requirements Engineering', skill: 7, logo: '', type: SkillType.ENGINEER },

    { name: 'GCloud/AWS/Azure, etc.', skill: 9, logo: '', type: SkillType.INFRA },
    { name: 'Kubernetes', skill: 7, logo: '', type: SkillType.INFRA },
    { name: 'Networking', skill: 7, logo: '', type: SkillType.INFRA },
    { name: 'IaC', skill: 8, logo: '', type: SkillType.INFRA },
    { name: 'Serverless', skill: 8, logo: '', type: SkillType.INFRA },

    { name: 'SQL DBs', skill: 9, logo: '', type: SkillType.TOOL },
    { name: 'NoSQL DBs', skill: 8, logo: '', type: SkillType.TOOL },
    { name: 'ElasticSearch', skill: 9, logo: '', type: SkillType.TOOL },
    { name: 'Redis', skill: 9, logo: '', type: SkillType.TOOL },
    { name: 'Rabbit/0 MQ', skill: 9, logo: '', type: SkillType.TOOL },
    { name: 'Kafka', skill: 9, logo: '', type: SkillType.TOOL },
    { name: 'Hadoop', skill: 9, logo: '', type: SkillType.TOOL },

    { name: 'Tech Team Management', skill: 10, logo: '', type: SkillType.MANAGE },
    { name: 'Product Management', skill: 10, logo: '', type: SkillType.MANAGE },
    { name: 'SDLC design', skill: 10, logo: '', type: SkillType.MANAGE },
    { name: 'Organizational Architecture', skill: 6, logo: '', type: SkillType.MANAGE },
    { name: 'Candidate sourcing', skill: 7, logo: '', type: SkillType.MANAGE },

    { name: 'Mentoring', skill: 10, logo: '', type: SkillType.PEOPLE },
    { name: 'Communication', skill: 10, logo: '', type: SkillType.PEOPLE },
    { name: 'Interviewing', skill: 8, logo: '', type: SkillType.PEOPLE },
    { name: 'Team Culture', skill: 9, logo: '', type: SkillType.PEOPLE }
]

export default skills;
