import React from 'react';
import { Box, Flex, Image, Link, Spacer, Text } from '@chakra-ui/react';
import ExpandableText from '../../components/ExpandableText';

interface IExperienceEntry {
    description: string;
    title: string;
    from: string;
    to?: string;
    companyName: string;
    companyUrl?: string | null;
    companyLogo?: any;
    location: string;
}

const ExperienceEntry: React.FC<IExperienceEntry> = ({ location, description, title, from, to, companyLogo, companyUrl, companyName }) => {

    return (
        <Flex direction='column' maxW={'50vw'} mb={5}>
            <Flex>
                {companyLogo && <Image src={require(`../../assets/logos/${companyLogo}`)} w='60px' h='60px' mr={2} /> }
                {!companyLogo && <Box w='60px' mr={2}/> }

                <Flex direction='row' w='100%'>
                    <Flex direction='column'>
                        <Text fontSize='md' fontWeight='bold'>
                            { title }
                        </Text>
                        <Text fontWeight='bold' size='sm' fontStyle='italic'>
                            { companyUrl && <Link isExternal href={companyUrl}>{ companyName }</Link> }
                            { !companyUrl && <Text>{ companyName }</Text> }
                        </Text>
                    </Flex>

                    <Spacer />
                    <Flex direction='column'>
                        <Text fontSize='xs' color='gray.600'>
                            { from } - { to ? to : '' }
                        </Text>
                        <Text fontSize='xs' color='gray.600'>
                            { location }
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <ExpandableText noOfLines={3} text={description} />
        </Flex>
    )
}

export default ExperienceEntry;
