// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        title:'Head of Engineering',
        from:'June 2024',
        companyName:'karpatkey',
        location: 'Lisbon',
        description: `karpatkey helps crypto organisations grow their assets responsibly and sustainably. We provide active treasury management, financial services, ecosystem growth and risk management solutions.
        
Together with the rest of the leadership I created the vision to transition from Services to Product company, where we launched two of our first products.
I grew the team, and managed them for personal and team growth. Proper processes around delivery and security were put in place.

**Tech**: · Web3 · GCloud · GraphQL · TS · Web3 · ElasticSearch

**Skills**: · Team Management · Hiring · Vision & Culture setting · SDLC and Process management
        `,
        companyUrl:'https://karpatkey.com/',
        companyLogo:'kpk-logo.png',
        keep: true,
    },
    {
        title:'CTO & Co-Founder',
        from:'Jan 2022',
        to: 'June 2024',
        companyName:'TripsTrade',
        location: 'London/Berlin',
        description: `TripsTrade uses blockchain and NFT technology to create a new open market for leases. 

        Property Owners can pre-sell their leases to de-risk their future revenues.
        Speculators get access to a new marketplace.
        Renters get the best price available to the market.
        All built in an open and permissionless manner
My proud achievements are delivering two perfectly working products under budget and before deadlines as well as 
keeping in line with client and investor demands, as well as mentoring and hiring the best damn team.

**Tech**: · Web3 · Solidity · AWS · GraphQL · TypeScript · SQL · ElasticSearch

**Skills**: · Fundraising · Team Management · Hiring · Vision & Culture setting
        `,
        companyUrl:'https://tripstrade.com/',
        companyLogo:'logo-tripstrade.svg',
        keep: true,
    },
    {
        title:'Software Engineer',
        location: 'London, UK',
        from:'May 2021',
        to:'Jan 2022',
        companyName:'ExodusPoint Capital',
        description:`I can't say much here other than I worked on the internal Trading Platform.
        
**Tech**: Popular F/E tech · Popular B/E tech · Not so popular B/E tech`,
        companyUrl:'https://www.exoduspoint.com/',
        companyLogo:'logo-exodus.jpeg',
        keep: true
    },
    {
        title:'Vice President (Software Engineer)',
        location: 'London, UK',
        from:'Nov 2017',
        to:'Apr 2021',
        companyName:'Goldman Sachs',
        description:`During my time in GS I went through various restructurings and promotion cycles.
        
Apart from mentoring younger colleagues and setting up our team's SDLC I delivered and made sure to internally market
the following products together with my team, while ensuring the integrity of our culture and keeping the high standards
that GS demands:

· ***FACET***: Part of the 'OneGS program' which saw cross-organizational collaboration to define the Client Entity.

· ***BrokerVotes***: I designed, built, marketed and delivered this project along with another colleague. BrokerVotes is an 
essential tool for Equities, FICC, IBD and Research that collects feedback from external clients along different dimensions.

· ***ClientHub/SMART***: Our internal CRM tool initially for Equities and then for Global Markets. Together with my team and my subordinates
we built the mobile app on top of the desktop tool. The last year I was there we integrated with Marquee (internal).

During my time there I interviewed an array of candidates and when we transitioned from 'Sales Tech' to 'Data Intelligence' departments
I was the top interviewer by a large margin (I was one of the few in the department that were experts in Python)

**Tech** Java · React • Kubernetes • ElasticSearch • Kafka • Slang

**Skills** · Team Management · Mentoring · Hiring · SDLC management
`,
        companyUrl:'https://goldmansachs.com',
        companyLogo:'logo-gs.svg',
        keep: true
    },
    {
        title:'Software Engineer',
        location: 'London, UK',
        from: 'Mar 2017',
        to: 'Nov 2021',
        companyName:'GrowthStreet',
        description:`Growth Street *offered* an overdraft facility for SMEs, funded by a P2P lending market. 

Responsibilities and product deliveries:

• Design and development of the market, payments, accounting and customers services.

• Led the front-end side of things, especially during the transition to React

• Created a custom Cron to calculate all the fees accumulated dynamically

• Mentored junior colleagues

**Tech** Python · Django • React`,
        companyUrl:'https://growthstreet.com',
        companyLogo:'logo-growth.jpeg',
        keep: true,
    },
    {
        title:'Software Engineer',
        location: 'London, UK',
        from:'Dec 2016',
        to:'Mar 2017',
        companyName:'NMR consultancy',
        description:`NMR *was* an integrator that supports, sells and maintains Cantemo's Portal technology.

I joined for the ReCAP project, funded by the EU, and my main responsibility was to gather requirements from major clients including Burberry, Royal Courts of Justice, Telegraph and others.
**Tech** Python · Django`,
        companyUrl:null,
        companyLogo:'logo-nmr.jpeg',
        keep: false,
    },
    {
        title:'Software Engineer',
        location: 'Cambridge, UK',
        from:'Jan 2015',
        to:'Dec 2016',
        companyName:'Orbitil',
        description:`Full-stack engineer. Working with Django, Python, Postgres, D3, and many other popular web technologies. 

• Delivered from prototype to production our B2B Project Mgmt SaaS tool.

• Collaborated with Research to deliver "scenario-based" NPV, IRR, ROI valuations, and projections of projects, option pricing, volatility assessment, and risk management.

• Designed and made interactive graphs, so users and managers can explore their data in new ways.

**Tech**: JavaScript · D3js · Python · Django · AWS`,
        companyUrl:'https://www.orbitil.com/',
        companyLogo:'logo-orbitil.jpeg',
        keep: true
    },
    {
        title:'Developer GW ST framework',
        location: 'Gothenburg, Sweden',
        from:'Jun 2014',
        to:'Sep 2014',
        companyName:'Ericsson',
        description:`During my time in Ericsson I developed two tools; 
The first was smoke testing Ericsson's main EPG test suite. Written in Python.
The second one generates dynamic configuration files for EPG servers. It helps to save testers' time and provides them with a concise option to configure the test server a 

Both of the tools are into production (upd. 2015)

**Tech**: Python · JavaScript`,
        companyUrl: 'https://www.ericsson.com',
        companyLogo:'logo-ericsson.jpeg',
        keep: true,
    },
    {
        title:'SW Consultant',
        location: 'Gothenburg, Sweden',
        from:'Mar 2014',
        to:'Aug 2014',
        companyName:'dputz AB',
        description: `During my time consulting for dputz AB, I designed a layered learning system using neural networks to predict the occurrence of fault codes in wind turbines based on historic faults in unrelated machines. The solution contained data cleaning, regression and classification methods.

**Tech** Python · Pandas · PyBrain`,
        keep: false
    },
    {
        title:'Production Planning Developer',
        location: 'Athens, Greece',
        from:'Sep 2012',
        to:'Dec 2012',
        companyName:'Hellenic Aerospace Industries',
        description:`As an intern in HAI, I was tasked to implement software in Visual Basic for the Production Planning department.
  
Afterwards I received training in area specific Electronics and in the architecture of mobile Radars in warfare.

**Tech**: Visual Basic · Java`,
        companyUrl:'https://www.haicorp.com/en/',
        companyLogo:'logo-hai.jpeg',
        keep: false
    },
];

export const otherExperience = [
    {
        title:'Software Contractor',
        location: 'London/Berlin',
        from:'Nov 2023',
        to:'Jan 2024',
        companyName:'VitaDAO',
        description:`I designed, developed and maintain one of their core projects, the VitaDAO TLDR portal.
        
VitaDAO is a decentralized, community-owned collective dedicated to funding and advancing early stage longevity science research. Our mission is to support researchers who are working to pre-empt the onset of age-related diseases, and to bring new therapeutics to market that allow people to live longer, healthier lives.
        
**Skills**: Technical Leadership · Solidity · React · TypeScript · GraphQL · Apollo`,
        companyUrl:'https://www.vitadao.com/',
        companyLogo:'logo-vita.jpeg',
        keep: true
    },
    {
        title:'Technology Advisor',
        location: 'London/Berlin',
        from:'Sep 2022',
        to:'Sep 2023',
        companyName:'Valhalla Network',
        description:`Valhalla Network is a decentralized autonomous organization that will establish a global network of community banks.
        
**Skills**: Technical Leadership · Solidity`,
        companyUrl:'https://www.valhallanetwork.io/',
        companyLogo:'logo-val.jpeg',
        keep: true
    },
]